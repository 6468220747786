
import Vue from "vue";
import {
  VRow,
  VCol,
  VCard,
  VCardTitle,
  VDivider,
  VBtn,
  VIcon
} from "vuetify/lib";
//mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import MenuButton, {
  MenuChildType,
  MenuType
} from "@/components/input/button/MenuButton.vue";
import {
  codeKbnFilter,
  PulldownResponse
} from "@/models/response/PulldownResponse";
import { CodeKbnList } from "@/models/codeMaster/Code";

export type Menu = MenuType & {
  children?: MenuType[];
};

export default Vue.extend({
  name: "Office",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VRow,
    VCol,
    VCard,
    VCardTitle,
    VDivider,
    VBtn,
    VIcon,
    MenuButton
  },
  data() {
    return {
      isOpen: false,
      noschedule: "",
      noservice: "",
      isolationChildren: [] as MenuType[],
      sheathChildren: [] as MenuType[],
      hcvRollIsolationChildren: [] as MenuChildType[],
      hcvRollUpperChildren: [] as MenuChildType[]
    };
  },
  computed: {
    menu() {
      return {
        dailyWorkReport: [
          {
            text: "伸線作業記録",
            children: [
              {
                text: "引き落とし作業記録",
                value: "SHiki",
                icon: "mdi-notebook"
              },
              {
                text: "錫引き作業記録",
                value: "SSuzu",
                icon: "mdi-notebook"
              },
              {
                text: "集合作業記録",
                value: "SSyugo",
                icon: "mdi-notebook"
              }
            ]
          },
          {
            text: "集合作業記録",
            value: "Assembly"
          },
          {
            text: "集合(細線)作業記録",
            value: "AssemblyThinwire"
          },
          {
            text: "撚線作業記録",
            value: "Yorisen"
          },
          {
            text: "絶縁押出(低圧)作業記録",
            children: this.isolationChildren
          },
          {
            text: "シース押出(低圧)作業記録",
            children: this.sheathChildren
          },
          {
            text: "HCV-CCV押出作業記録",
            value: ""
          },
          {
            text: "HCV-CCV巻取作業記録",
            children: [
              {
                text: "絶縁作業記録",
                value: "HcvRollIsolation",
                icon: "mdi-notebook",
                children: this.hcvRollIsolationChildren
              },
              {
                text: "上出し作業記録",
                value: "HcvRollUpper",
                icon: "mdi-notebook",
                children: this.hcvRollUpperChildren
              }
            ]
          },
          {
            text: "加硫作業記録",
            value: "Vulcanizing"
          },
          {
            text: "被鉛作業記録",
            value: "Hien"
          },
          {
            text: "剝鉛作業記録",
            value: "Hakuen"
          },
          {
            text: "ゴム練り作業記録",
            value: "Rubber"
          },
          {
            text: "検査作業記録",
            children: [
              {
                text: "外観検査作業記録",
                value: "KGaikan",
                icon: "mdi-notebook"
              },
              {
                text: "電気試験作業記録",
                value: "KDenki",
                icon: "mdi-notebook"
              }
            ]
          },
          {
            text: "修理作業記録",
            value: "Repair"
          },
          {
            text: "出荷作業記録",
            value: "Shukka"
          },
          {
            text: "加工作業記録",
            value: "Machining"
          },
          {
            text: "シールド作業記録",
            value: "Shield"
          }
        ] as Menu[]
      };
    }
  },
  methods: {
    async routing(menu: MenuType) {
      const dialog = new ShowDialogs();
      if (!menu.value) dialog.$info("準備中です。", "情報");
      this.$router.push({ name: menu.value, params: menu.params });
    },
    async search() {
      console.log("search");
      this.$router.push("/dailyworkreport/search");
    },
    async shain() {
      console.log("shain");
      this.$router.push("/list/shain");
    },
    async code() {
      console.log("code");
      this.$router.push("/list/code-master");
      //this.$info("準備中です。");
    }
  },
  async created() {
    console.log("getItems start");
    const showDialogs = new ShowDialogs();
    const api = new Api();

    try {
      //apiパス
      const codeMasterPath = api.Paths.codeMasterPulldown;
      const codeMaster = await api.$get<PulldownResponse[]>(codeMasterPath);

      //コードマスタのデータをすべて取得
      //絶縁機械名
      const machine = codeKbnFilter(codeMaster, CodeKbnList.OSDS);
      for (const [i, e] of machine.entries()) {
        if (i < 4) {
          this.isolationChildren.push({
            text: e.name,
            value: "Isolation",
            icon: "mdi-notebook",
            params: { machineCode: e.code, machineName: e.name }
          });
        }
        if (i < 5) {
          this.sheathChildren.push({
            text: e.name,
            value: "Sheath",
            icon: "mdi-notebook",
            params: { machineCode: e.code, machineName: e.name }
          });
        }
      }

      const hcvccv = codeKbnFilter(codeMaster, CodeKbnList.HCVCCV);
      this.hcvRollIsolationChildren = hcvccv.map(e => ({
        text: e.name,
        value: "HcvRollIsolation",
        icon: "mdi-notebook",
        params: { machineCode: e.code, machineName: e.name }
      }));
      this.hcvRollUpperChildren = hcvccv.map(e => ({
        text: e.name,
        value: "HcvRollUpper",
        icon: "mdi-notebook",
        params: { machineCode: e.code, machineName: e.name }
      }));
    } catch (e) {
      if (e instanceof Error) {
        showDialogs.$error(e.message);
      } else {
        showDialogs.$error("予期せぬエラーが発生しました");
      }
    } finally {
      console.log("getItems end");
    }
  }
});
